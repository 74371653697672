import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;}
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 1rem;
`

const NapoliList = styled.ul `
    list-style: initial;
    padding-left: 2rem;

    li {
        margin: 0.3em 0;
    }
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Na pizzu do Neapole? Klidně.</Title>
            <Info>
                <Author>Julia</Author>
                <Date>18. 6. 2023</Date>
            </Info>
            <p>Na pizzu si můžeme dnes už zajít kdekoliv, ale co takhle si zaletět na prodloužený víkend do Neapole na pizzu? Máte pocit, že to bude hrozně drahé? No takhle, něco vás to stát bude, ale kolik konkrétně? Pojďme se na to podívat.</p>
            <p>Tak třeba letenky, v průběhu roku do Neapole létá Ryanair a Wizzair. Letenky se dají koupit cca od 1000,- Kč, mám tu pro vás několik příkladů.</p>
            <p>Třeba termín od čtvrtka do soboty (05. - 07.říjen).</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/napoli/napoli1.png" alt="Snímek z rezervačního systému společnosti Wizzair na spojení Praha - Neapol 5. 10. 2023 za 669 Kč a zpáteční let 7. 10. 2023 za 949 Kč."></StaticImage>
            </StyledFigure>
            <p>Samozřejmě je to pouze náhodný termín. V průběhu roku se ceny mění a opravdu se dá pořídit letenka i za 1000 Kč, pokud to člověk průběžně sleduje (nebo se obrátíte včas na nás a my vám to pohlídáme).</p>
            <p>Zůstaneme u stejného termínu a podíváme se, kolik nás vyjde ubytování:</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/napoli/napoli2.png" alt="Snímek z rezervačního portálu Booking.com, dvoulůžkový pokoj v Napoli Regina za 3711 Kč a v Casa Adelaide za 2212 Kč."></StaticImage>
            </StyledFigure>
            <p>Máme tady ubytování cenu za dvě osoby na dvě noci - vlastní pokoj, v docházkové vzdálenosti od centra města, včetně snídaně.</p>
            <p>A kam na pizzu v Neapoli? Možností je hned několik, ale já vám osobně doporučím tuto – <a href="https://goo.gl/maps/XaqR6fgKQeydG6bT7">L'Antica Pizzeria da Michele</a>. Pokud se rozhodnete sem vyrazit, nečekejte, až opravdu budete mít velký hlad. Jsou tu fronty. Ale pokud si řeknete, že byste si rádi dali Pizzu za hodinku, klidně. Buď si ji můžete vzít tzv. “take away” nebo na místě. Obojí znamená, že si musíte vystát frontu na lístek asi 15-25 minut a následně vyčkat, až na vás přijde řada. Ale nevěšte hlavu, čekání si můžete zpříjemnit v jedné z kaváren v okolí. A navíc čekání rozhodně stojí za to! Samotná pizza stojí od 5 do 5,50 EUR. Velikost je akorát pro jednu osobu. Doporučují si vzít dvě, pokud jste ve dvou. A zároveň si můžete vybrat každou jinou a ochutnat dva různé druhy.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/napoli/napoli3.jpeg" alt="Neapolská pizza"></StaticImage>
            </StyledFigure>
            <p>A co takhle dělat během třídenního pobytu v Neapoli? Možností je hned několik:</p>
            <NapoliList>
                <li>projít si uličky v centru města</li>
                <li>navštívit jedno z nejhezčích <a href="https://goo.gl/maps/gQmnEagpqPmvzU7GA">podzemí</a></li>
                <li><a href="https://goo.gl/maps/ohTFAet7ruKexGbq9">Castel dell'Ovo</a></li>
                <li>podívat se k moři </li>
                <li>zajít na Aperol nebo víno</li>
                <li>zajít na fotbal nebo jen navštívit stadion slavného Maradony</li>
            </NapoliList>
            <p>Myslím, že těchto pár tipů vám na prodloužený víkend bohatě stačí.</p>
            <p>Co se týká zmíněného koktejlu Aperol. Ten se zde prodává opravdu na každém rohu a dokonce hned v několika velikostech.</p>
            <p>Small - od 1 EUR do 2,50 EUR,<br /> Medium - od 3 EUR,<br />Big - za 5 EUR</p>
            <p>Večer v centru města dokonce potkáte několik stánků na ulici, kde budou nabízet rozlévané prosecco za 1 EUR.</p>
            <p>Musím říct, že mě Neapol uchvátila. Byla jsem zde začátkem května poprvé a rozhodně ne naposledy. Svou příští návštěvu rozhodně spojím s návštěvou národního parku Vesuv a městečka Salerno směrem na jih od Neapole nebo Pompejemi.</p>
            <p>Pokud si to shrneme, je to vlastně cenově dostupné. Ano, dát si pizzu v Praze (nebo ve vašem městě, kde bydlíte) může být levnější, ale Neapol je Neapol a rozhodně stojí za návštěvu.</p>
            <p>Příjemné cestování.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/napoli/napoli4.jpeg" alt="Ulice v Neapoli"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/napoli/napoli5.jpeg" alt="Ulice v Neapoli"></StaticImage>
                </StyledFigure>
            </DoubleImage>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
